import { Divider } from "antd";
import Name from "./name";
import Description from "./description";
import Keywords from "./keywords";
import URL from "./url";
import IsFullTitle from "./is_full_title";
import Type from "./type";
import Image from "./image";
import Title from "./title";
import FooterText from "./footer_text";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <URL {...props} />
      </div>
      <div className="col-span-12">
        <Type {...props} />
      </div>
      <div className="col-span-12">
        <IsFullTitle {...props} />
      </div>
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Description {...props} />
      </div>
      <div className="col-span-12">
        <Keywords {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <Title {...props} />
      </div>
      <div className="col-span-12">
        <FooterText {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <Image {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="mt-2 mb-0" />
      </div>
    </div>
  );
}

import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function FormInput(props) {
  const [data, setData] = useState([]);
  const type = props?.form?.getFieldValue("type");

  useEffect(() => {
    API.Articles.getTypes({ limit: 100 }).then((res) => {
      if (res.error || !res) {
        return;
      }

      setData(res?.data?.rows?.filter((row) => row?.meta?.allow_create) || []);
    });
  }, []);

  return (
    <Form.Item
      {...props}
      name="type_id"
      className="w-full"
      label="Тип материала"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите тип материала",
        },
      ]}
    >
      <Select
        loading={!data.length}
        placeholder="Выберите"
        className="w-full"
        showSearch
        disabled={type?.id === 7}
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data.map((val) => {
          return {
            label: val.name,
            value: val.id,
            disabled: val?.id === 7,
          };
        })}
      />
    </Form.Item>
  );
}

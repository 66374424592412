import { useEffect, useState } from "react";
import { Loading } from "@components";
import { message, Button, Modal, Input, Divider, Tooltip } from "antd";
import { LinkOutlined, CopyOutlined } from "@ant-design/icons";
import * as API from "@api/shortlinks";

const SOURCE_ICON_MAP = {
  facebook: (
    <img
      src="/img/icon-fb.svg"
      className="block"
      width={20}
      height={20}
      alt="Facebook"
    />
  ),
  twitter: (
    <img
      src="/img/icon-x.svg"
      className="block"
      width={20}
      height={20}
      alt="X"
    />
  ),
  telegram: (
    <img
      src="/img/icon-tg.svg"
      className="block"
      width={20}
      height={20}
      alt="Telegram"
    />
  ),
  instagram: (
    <img
      src="/img/icon-ig.svg"
      className="block"
      width={20}
      height={20}
      alt="Instagram"
    />
  ),
  vk: (
    <img
      src="/img/icon-vk.svg"
      className="block"
      width={20}
      height={20}
      alt="VK"
    />
  ),
  threads: (
    <img
      src="/img/icon-th.svg"
      className="block"
      width={20}
      height={20}
      alt="Threads"
    />
  ),
};

function Shortlinks({ article }) {
  const [loading, setLoading] = useState(false);
  const [shortlinks, setShortlinks] = useState([]);

  useEffect(() => {
    setLoading(true);

    API.create({ article_id: article.id })
      .then(async (res) => {
        if (!res || res?.error) {
          throw new Error(
            res?.message || "Невозможно загрузить список коротких ссылок"
          );
        }

        setShortlinks(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return message.error(
          err.message || "Невозможно загрузить список коротких ссылок",
          2
        );
      })
      .finally(() => setLoading(false));
  }, [article.id]);

  if (loading) {
    return <Loading compact />;
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <p>
        Скопируйте короткую ссылку для нужной соц.сети, чтобы учитывать
        аналитику переходов по каждому источнику:
      </p>
      {shortlinks?.map((link) => {
        const source = link.url.match(/utm_source=([^&]*)/)[1];

        return (
          <Input
            key={link.uid}
            className="w-full"
            addonBefore={SOURCE_ICON_MAP[source]}
            suffix={
              <Tooltip title={`Скопировать ссылку ${source}`} placement="left">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://kapital.kz/to/${link.uid}`
                    );
                    message.success(`Ссылка ${source} скопирована!`, 2);
                  }}
                  icon={<CopyOutlined />}
                  type="text"
                />
              </Tooltip>
            }
            readOnly
            value={`https://kapital.kz/to/${link.uid}`}
            onFocus={(e) => e.target.select()}
          />
        );
      })}
      <Divider className="mb-2" />
    </div>
  );
}

export default function LinksButton({ article }) {
  const loadModal = () =>
    Modal.info({
      title: "Ссылки на материал «" + article.title + "»",
      icon: <LinkOutlined />,
      centered: true,
      width: 700,
      content: <Shortlinks article={article} />,
      maskClosable: true,
      closable: true,
      okText: "Готово",
    });

  return (
    <Tooltip title="Ссылки на материал" placement="top">
      <Button
        disabled={article?.status !== "published"}
        icon={<LinkOutlined />}
        onClick={loadModal}
      />
    </Tooltip>
  );
}

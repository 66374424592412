import { Form, Input } from "antd";
import { HTMLEditor } from "@components";

export default function FormInput(props) {
  return (
    <Form.Item
      label="Текст в футере"
      tooltip={
        <img
          src="/img/previews/category-description.png"
          className="w-full block rounded-sm"
          alt="Дополнительный текст в футере"
        />
      }
    >
      <HTMLEditor
        className="w-full mt-2"
        value={props?.form?.getFieldValue("footer_text")}
        onChange={(value) => {
          props?.form?.setFieldsValue({
            footer_text: value,
          });
        }}
      />
    </Form.Item>
  );
}

import { Form, DatePicker } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="published_at"
      className="w-full"
      label="Дата публикации"
      tooltip="Дата публикации не влияет на запланированную дату. Дата публикации будет установлена автоматически в момент публикации, если оставить поле пустым."
    >
      <DatePicker
        className="w-full"
        showTime
        format="DD.MM.YYYY HH:mm"
        placeholder="Необязательно"
      />
    </Form.Item>
  );
}

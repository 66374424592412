import { useEffect, useState } from "react";
import { Image as AntImage, message, Button } from "antd";
import { LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { Imgproxy } from "@utils";
import config from "@config";
import * as API from "@api";

export default function Photos({ form }) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(
    form.getFieldValue("preview_thumbnail") || null
  );

  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading || photo,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
    },
    onDrop: async (file) => {
      if (Array.isArray(file)) {
        file = file[0];
      }

      if (file.size > 5 * 1000 * 1000) {
        return message.error(
          "Размер файла не должен превышать 5MB, пожалуйста, выберите другой файл."
        );
      }

      try {
        setLoading(true);
        const _res = await API.Static.uploadImage({
          file,
        });

        if (!_res || !_res?.data?.path) {
          throw new Error();
        }

        setLoading(false);
        setPhoto(_res?.data?.path);
        return message.success("Изображение загружено");
      } catch (error) {
        setLoading(false);
        return message.error(
          `Ошибка при загрузке изображения: ${error.message}`,
          2
        );
      }
    },
  });

  useEffect(() => {
    form.setFieldsValue({ preview_thumbnail: photo });
  }, [photo]);

  const onImageDelete = (idx) => {
    setPhoto(null);
  };

  return (
    <div>
      {photo ? (
        <div>
          <AntImage
            width="100%"
            height={400}
            src={Imgproxy.getImage(photo)}
            className="object-cover object-center"
          />
          <Button
            onClick={onImageDelete}
            className="my-2"
            block
            danger
            type="text"
          >
            Удалить фото
          </Button>
        </div>
      ) : (
        <div
          className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 rounded-md my-5 px-2 py-7"
          {...getRootProps()}
        >
          <input
            multiple={false}
            {...getInputProps()}
            accept=".png, .jpg, .jpeg"
          />
          <p className="mx-auto block w-fit text-4xl mb-3 text-primary">
            {loading ? <LoadingOutlined /> : <PictureOutlined />}
          </p>
          <p className="text-sm text-black w-full block text-center mb-1">
            Нажмите или перетащите, чтобы загрузить фото
          </p>
          <p className="text-xs text-neutral-500 w-full block text-center">
            Макс. размер файла: 5MB
          </p>
        </div>
      )}
    </div>
  );
}

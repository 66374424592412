import { Modal, Input, Select, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "@api/people";

export default function NewPerson({ open, onCancel }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    type: "dossier",
  });

  const onSubmit = () => {
    if (!data?.first_name || !data?.last_name || !data?.type) {
      return message.error("Заполните все обязательные поля", 2);
    }

    data.full_name = `${data.first_name} ${data.last_name}`;

    if (data.middle_name) {
      data.full_name = data.full_name + ` ${data.middle_name}`;
    }

    setLoading(true);

    return API.create(data)
      .then((res) => {
        setLoading(false);
        if (res?.error || !res?.data?.id) {
          throw new Error(res?.message);
        }

        message.success(
          "Персона создана. Пожалуйста, продолжите заполнение данных.",
          3
        );

        // redirect to /people/edit/:id
        return navigate(`/people/edit/${res?.data?.id}`);
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          "Ошибка при создании персоны. Возможно, такая запись уже существует.",
          3
        );
      });
  };

  const onValueChange = (key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Modal
      open={open}
      title="Добавление новой персоны"
      onCancel={onCancel}
      okButtonProps={{
        loading,
        disabled: loading,
      }}
      onOk={onSubmit}
      cancelButtonProps={{ loading, disabled: loading }}
      okText="Продолжить"
      cancelText="Отмена"
      centered
      closable={!loading}
      maskClosable={!loading}
    >
      <div className="flex flex-col gap-2">
        <div>
          <span className="text-xs font-normal text-neutral-500 block mb-1">
            Имя
          </span>
          <Input
            onChange={(e) => onValueChange("first_name", e.target.value)}
            className="w-full"
            placeholder="Введите имя"
            value={data.first_name}
          />
        </div>
        <div>
          <span className="text-xs font-normal text-neutral-500 block mb-1">
            Фамилия
          </span>
          <Input
            onChange={(e) => onValueChange("last_name", e.target.value)}
            className="w-full"
            placeholder="Введите фамилию"
            value={data.last_name}
          />
        </div>
        <div>
          <span className="text-xs font-normal text-neutral-500 block mb-1">
            Отчество
          </span>
          <Input
            onChange={(e) => onValueChange("middle_name", e.target.value)}
            className="w-full"
            placeholder="Введите отчество (необязательно)"
            value={data.middle_name}
          />
        </div>
        <div>
          <span className="text-xs font-normal text-neutral-500 block mb-1">
            Тип персоны
          </span>
          <Select
            onChange={(value) => onValueChange("type", value)}
            allowClear={false}
            placeholder="Выберите тип персоны"
            className="w-full"
            value={data.type}
            options={[
              {
                label: "Досье",
                value: "dossier",
              },
              {
                label: "Эксперт",
                value: "expert",
              },
              {
                label: "Досье + Эксперт",
                value: "both",
              },
            ]}
          />
        </div>
      </div>
    </Modal>
  );
}
